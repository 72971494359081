export default {
  methods: {
    /*
     * @function - animateEntry
     * Calls reference component if exists to set fade animation. This function can be overwritten if any component requires a specific entry animation
     * @param - none
     */
    animateEntry() {
      const reference = this.$refs[this.$options.name];

      if (!reference) {
        return;
      }

      this.m_animateFade(reference, 1000, "in");
    }
  },
  created() {
    this.$bus.$on("LoadingMask: loaded", this.animateEntry);
  },
  mounted() {
    if (this.$store.getters["Global/assetsAreReady"]) {
      this.animateEntry(); // Init reference component if exists after assets are loaded (if another page is first loaded and then moves back)
    }
  },
  beforeDestroy() {
    this.$bus.$off("LoadingMask: loaded", this.animateEntry);
  }
};
