<template>
  <app>
    <template v-slot:languageSelection>
      <language-selection />
    </template>
    <template v-slot:content>
      <terms-and-conditions />
    </template>
  </app>
</template>

<script>
const App = () => import(/* webpackChunkName: "TermsAndConditionsPage" */ "@/App.vue");
const LanguageSelection = () =>
  import(
    /* webpackChunkName: "TermsAndConditionsPage" */ "@/components/Global/LanguageSelection.vue"
  );
const TermsAndConditions = () =>
  import(/* webpackChunkName: "TermsAndConditionsPage" */ "@/views/TermsAndConditions.vue");

export default {
  name: "TermsAndConditionsApp",
  components: {
    App,
    LanguageSelection,
    TermsAndConditions
  }
};
</script>
