export default {
  data() {
    return {
      m_imagotype: {
        o: null,
        v: null,
        nca: null,
        ega: null,
        loop: false
      }
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.Global.window.width;
    },
    windowHeight() {
      return this.$store.state.Global.window.height;
    }
  },
  methods: {
    /*
     * @function - m_animateEntry
     * This function sets entry animation for each component, acording scroll position
     * @param - configuration (Object): configuration for entry animation
     * @param - configuration.component (Object): DOM element to animate
     * @param - configuration.method (String): Method name to run after condition is true
     * @param - configuration.operationBreakpoint (String): LARGER, SHORTER, LARGEROREQUAL, SHORTEROREQUAL; value to delimit breakpoint operation
     * @param - configuration.behavior (String/Number): bottom, top, middle, Number; value to define at what Y position the condition is true
     * @param - configuration.boundary (Number): breakpoint to define if animation is valid or not
     */
    m_animateEntry(configuration) {
      // method, breakpoint, behavior, boundary
      configuration.forEach(({ component, method, operationBreakpoint, behavior, boundary }) => {
        const runAnimation =
          (operationBreakpoint === "LARGER" && this.windowWidth > boundary) ||
          (operationBreakpoint === "SHORTER" && this.windowWidth < boundary) ||
          (operationBreakpoint === "LARGEROREQUAL" && this.windowWidth >= boundary) ||
          (operationBreakpoint === "SHORTEROREQUAL" && this.windowWidth <= boundary);

        if (runAnimation) {
          const scrollListener = () => {
            if (this.m_getPositionFireEvent(component, behavior) < 0) {
              this[method]();
              document.removeEventListener("scroll", scrollListener);
            }
          };

          document.addEventListener("scroll", scrollListener);
          scrollListener();
        }
      });
    },
    /*
     * @function - m_getPositionFireEvent
     * This function returns scroll position to fire animation event according a bahavior and component position
     * @param - component (Object): DOM element to animate
     * @param - behavior (String/Number): bottom, top, middle, Number; value to define at what Y position the condition is true
     */
    m_getPositionFireEvent(component, behavior) {
      const { offsetHeight } = component;
      const { top } = component.getBoundingClientRect();
      let positionFireEvent = null;

      switch (behavior) {
        case "top":
          positionFireEvent = top - this.windowHeight;
          break;
        case "bottom":
          positionFireEvent = top - this.windowHeight + offsetHeight;
          break;
        case "middle":
          positionFireEvent = top - this.windowHeight + offsetHeight / 2;
          break;
        default:
          positionFireEvent = isNaN(behavior)
            ? top - this.windowHeight
            : top - this.windowHeight + (offsetHeight * behavior) / 100;
      }

      return positionFireEvent;
    },
    /*
     * @function - m_animateImagotype
     * This function generates the onca vega imagotype animation
     * @param - component (Object): onca vega imagotype DOM element
     * @param - animationStep (Number): Defines animation step
     */
    m_animateImagotype(component, animationStep = 1500) {
      // Get svg and svg elements from object document
      const svgObject = component;
      this.m_imagotype.o = svgObject.getElementById("O_rasterizado");
      this.m_imagotype.v = svgObject.getElementById("V-2");
      this.m_imagotype.nca = svgObject.getElementById("nca_rasterizado");
      this.m_imagotype.ega = svgObject.getElementById("ega_rasterizado");

      // Init timeline
      this.$dependencies.anime
        .timeline()
        .add({
          // Add first animation for O
          targets: this.m_imagotype.o,
          duration: animationStep,
          easing: "easeInOutBack",
          rotate: "+=1turn",
          scale: [1, 0.9, 1]
        })
        .add(
          {
            // Add animation for V
            targets: this.m_imagotype.v,
            duration: animationStep,
            easing: "easeInOutSine",
            scale: [0, 1],
            translateY: ["50%", "0"]
          },
          `-=${animationStep / 2}`
        )
        .add(
          {
            // Add animation for nca
            targets: this.m_imagotype.nca,
            duration: animationStep,
            easing: "linear",
            opacity: [0, 1]
          },
          `-=${animationStep / 2}`
        )
        .add(
          {
            // Add animation for ega
            targets: this.m_imagotype.ega,
            duration: animationStep,
            easing: "linear",
            opacity: [0, 1],
            complete: () => {
              if (!this.m_imagotype.loop) {
                this.m_removeImagotypeAnimation();
              }
            }
          },
          `-=${animationStep / 2}`
        );
    },
    /*
     * @function - m_animateIsotype
     * This function generates the onca vega isotype animation
     * @param - component (Object): onca vega isotype DOM element
     * @param - animationStep (Number): Defines animation step
     * @param - loop (Boolean): Animation is looped or not
     */
    m_animateIsotype(component, animationStep = 1500, loop = false) {
      // Get svg and svg elements from object document
      const svgObject = component;
      this.m_imagotype.o = svgObject.getElementById("O_rasterizado");
      this.m_imagotype.v = svgObject.getElementById("V");
      this.m_imagotype.loop = loop;

      // Init timeline
      const timeline = this.$dependencies.anime
        .timeline({ loop: this.m_imagotype.loop })
        .add({
          // Add first animation for O
          targets: this.m_imagotype.o,
          duration: animationStep,
          easing: "easeInOutBack",
          rotate: "+=1turn",
          scale: [1, 0.9, 1]
        })
        .add(
          {
            // Add first animation for V
            targets: this.m_imagotype.v,
            duration: animationStep,
            easing: "easeInOutSine",
            scale: [0, 1],
            translateY: ["50%", "0"],
            complete: () => {
              if (!this.m_imagotype.loop) {
                this.m_removeImagotypeAnimation();
              }
            }
          },
          `-=${animationStep / 2}`
        );

      if (this.m_imagotype.loop) {
        timeline.add(
          {
            // Add second animation for V
            targets: this.m_imagotype.v,
            duration: animationStep / 2,
            easing: "linear",
            opacity: [1, 0]
          },
          `+=${animationStep / 2}`
        );
      }
    },
    /*
     * @function - m_removeImagotypeAnimation
     * This function removes onca vega imagotype animation elements referred from memory
     * @param - none
     */
    m_removeImagotypeAnimation() {
      this.$dependencies.anime.remove([
        this.m_imagotype.o,
        this.m_imagotype.v,
        this.m_imagotype.nca,
        this.m_imagotype.ega
      ]);
    },
    /*
     * @function - m_animateFade
     * This function generates fade animation for specific DOM element
     * @param - component (Object): DOM element to animate
     * @param - duration (Number): Defines animation duration
     * @param - type (String): 'in' for fade in animation or 'out' for fade out animation
     * @param - delay (Number): Milliseconds to wait before animation starts
     * @param - unsubscribe (Boolean): Element will unsubscribe or not after animation is done
     */
    m_animateFade(component, duration, type = "out", delay = 0, unsubscribe = true) {
      return new Promise(resolve => {
        const opacity = type === "out" ? [1, 0] : [0, 1];

        this.$dependencies.anime({
          targets: component,
          easing: "linear",
          duration,
          delay,
          opacity,
          complete: () => {
            if (unsubscribe) {
              this.$dependencies.anime.remove([component]);
            }
            resolve();
          }
        });
      });
    },
    /*
     * @function - m_animateTranslation
     * This function generates translate animation for specific DOM element
     * @param - component (Object): DOM element to animate
     * @param - duration (Number): Defines animation duration
     * @param - initialX (Number): Pixels to start from in X axis
     * @param - initialY (Number): Pixels to start from in Y axis
     * @param - finalX (Number): Pixels to finish in X axis
     * @param - finalY (Number): Pixels to finish in Y axis
     * @param - delay (Number): Milliseconds to wait before animation starts
     * @param - unsubscribe (Boolean): Element will unsubscribe or not after animation is done
     */
    m_animateTranslation(
      component,
      duration,
      initialX = 0,
      initialY = 0,
      finalX = 0,
      finalY = 0,
      delay = 0,
      unsubscribe = true
    ) {
      return new Promise(resolve => {
        this.$dependencies.anime({
          targets: component,
          easing: "linear",
          duration,
          delay,
          translateX: [initialX, finalX],
          translateY: [initialY, finalY],
          complete: () => {
            if (unsubscribe) {
              this.$dependencies.anime.remove([component]);
            }
            resolve();
          }
        });
      });
    }
  }
};
