export default {
  apiVersion: null,
  env: process.env,
  window: {
    scroll: 0,
    width: null,
    height: null
  },
  online: true,
  assets: {
    toLoad: ["translations"],
    loaded: []
  },
  languages: [],
  currentLanguage: null,
  currentPage: null
};
