<template>
  <app>
    <template v-slot:languageSelection>
      <language-selection />
    </template>
    <template v-slot:content>
      <router-view />
    </template>
  </app>
</template>

<script>
const App = () => import(/* webpackChunkName: "MyInterestsPage" */ "@/App.vue");
const LanguageSelection = () =>
  import(/* webpackChunkName: "MyInterestsPage" */ "@/components/Global/LanguageSelection.vue");

export default {
  name: "MyInterestsApp",
  components: {
    App,
    LanguageSelection
  }
};
</script>
