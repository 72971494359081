import Vue from "vue";
import VueRouter from "vue-router";

const MyInterests = () => import(/* webpackChunkName: "MyInterestsPage" */ "@/views/MyInterests");
const MyInterestsAbstract = () =>
  import(/* webpackChunkName: "MyInterestsPage" */ "@/views/MyInterests/MyInterestsAbstract");
const Engineering = () =>
  import(/* webpackChunkName: "EngineeringPage" */ "@/views/MyInterests/Engineering");
const Business = () =>
  import(/* webpackChunkName: "BusinessPage" */ "@/views/MyInterests/Business");
const Sports = () => import(/* webpackChunkName: "SportsPage" */ "@/views/MyInterests/Sports");
const Arts = () => import(/* webpackChunkName: "ArtsPage" */ "@/views/MyInterests/Arts");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MyInterests",
    component: MyInterests,
    redirect: { name: "MyInterestsAbstract" },
    children: [
      {
        path: "introduccion",
        name: "MyInterestsAbstract",
        component: MyInterestsAbstract
      },
      {
        path: "ingenieria",
        name: "Engineering",
        component: Engineering
      },
      {
        path: "empresarial",
        name: "Business",
        component: Business
      },
      {
        path: "deportes",
        name: "Sports",
        component: Sports
      },
      {
        path: "artes",
        name: "Arts",
        component: Arts
      }
    ]
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let newPosition = { x: 0, y: 0 };

    return new Promise(resolve =>
      setTimeout(() => {
        if (savedPosition) {
          newPosition = savedPosition;
        } else if (to.hash) {
          newPosition = { selector: to.hash, offset: { x: 0, y: 60 } };
        }

        resolve(newPosition);
      }, 150)
    );
  }
});

export default router;
