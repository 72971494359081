import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";

Vue.config.devtools = modules.Global.state.env.ENABLE_DEVTOOLS;

Vue.use(Vuex);

export default new Vuex.Store({ modules });
