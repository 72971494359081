import Vue from "vue";
import VueRouter from "vue-router";

const Portfolio = () => import(/* webpackChunkName: "PortfolioPage" */ "@/views/Portfolio");
const Neysla = () => import(/* webpackChunkName: "PortfolioPage" */ "@/views/Portfolio/Neysla");
const NeyslaInstallation = () =>
  import(/* webpackChunkName: "PortfolioPage" */ "@/views/Portfolio/Neysla/Installation");
const NeyslaDirectUsage = () =>
  import(/* webpackChunkName: "NeyslaDirectUsagePage" */ "@/views/Portfolio/Neysla/DirectUse");
const NeyslaModelerUsage = () =>
  import(/* webpackChunkName: "NeyslaModelerUsagePage" */ "@/views/Portfolio/Neysla/ModelerUse");
const Einapage = () => import(/* webpackChunkName: "EinapagePage" */ "@/views/Portfolio/Einapage");
const EinapageInstallation = () =>
  import(/* webpackChunkName: "EinapagePage" */ "@/views/Portfolio/Einapage/Installation");
const EinapageUsage = () =>
  import(/* webpackChunkName: "EinapageUsagePage" */ "@/views/Portfolio/Einapage/Usage");
const BirthdayGift = () =>
  import(/* webpackChunkName: "BirthdayGiftPage" */ "@/views/Portfolio/BirthdayGift");
const WeddingInvitation = () =>
  import(/* webpackChunkName: "WeddingInvitationPage" */ "@/views/Portfolio/WeddingInvitation");
const Xterminate = () =>
  import(/* webpackChunkName: "XterminatePage" */ "@/views/Portfolio/Xterminate");
const XterminateInstructions = () =>
  import(/* webpackChunkName: "XterminatePage" */ "@/views/Portfolio/Xterminate/Instructions");
const XterminateGame = () =>
  import(/* webpackChunkName: "XterminateGamePage" */ "@/views/Portfolio/Xterminate/Game");
const Memoram = () => import(/* webpackChunkName: "MemoramPage" */ "@/views/Portfolio/Memoram");
const MemoramInstructions = () =>
  import(/* webpackChunkName: "MemoramPage" */ "@/views/Portfolio/Memoram/Instructions");
const MemoramGame = () =>
  import(/* webpackChunkName: "MemoramGamePage" */ "@/views/Portfolio/Memoram/Game");
const BaseballBate = () =>
  import(/* webpackChunkName: "BaseballBatePage" */ "@/views/Portfolio/BaseballBate");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Portfolio",
    component: Portfolio,
    redirect: { name: "Neysla" },
    children: [
      {
        path: "neysla",
        name: "Neysla",
        component: Neysla,
        redirect: { name: "NeyslaInstallation" },
        children: [
          {
            path: "instalacion",
            name: "NeyslaInstallation",
            component: NeyslaInstallation
          },
          {
            path: "uso-directo",
            name: "NeyslaDirectUsage",
            component: NeyslaDirectUsage
          },
          {
            path: "uso-como-modelador",
            name: "NeyslaModelerUsage",
            component: NeyslaModelerUsage
          }
        ]
      },
      {
        path: "einapage",
        name: "Einapage",
        component: Einapage,
        redirect: { name: "EinapageInstallation" },
        children: [
          {
            path: "instalacion",
            name: "EinapageInstallation",
            component: EinapageInstallation
          },
          {
            path: "uso",
            name: "EinapageUsage",
            component: EinapageUsage
          }
        ]
      },
      {
        path: "regalo-virtual-de-cumpleanios",
        name: "BirthdayGift",
        component: BirthdayGift
      },
      {
        path: "invitacion-digital-de-boda",
        name: "WeddingInvitation",
        component: WeddingInvitation
      },
      {
        path: "xterminate",
        name: "Xterminate",
        component: Xterminate,
        redirect: { name: "XterminateInstructions" },
        children: [
          {
            path: "instrucciones",
            name: "XterminateInstructions",
            component: XterminateInstructions
          },
          {
            path: "jugar",
            name: "XterminateGame",
            component: XterminateGame
          }
        ]
      },
      {
        path: "memoram",
        name: "Memoram",
        component: Memoram,
        redirect: { name: "MemoramInstructions" },
        children: [
          {
            path: "instrucciones",
            name: "MemoramInstructions",
            component: MemoramInstructions
          },
          {
            path: "jugar",
            name: "MemoramGame",
            component: MemoramGame
          }
        ]
      },
      {
        path: "bate-de-baseball",
        name: "BaseballBate",
        component: BaseballBate
      }
    ]
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let newPosition = { x: 0, y: 0 };

    return new Promise(resolve =>
      setTimeout(() => {
        if (savedPosition) {
          newPosition = savedPosition;
        } else if (to.hash) {
          newPosition = { selector: to.hash, offset: { x: 0, y: 60 } };
        }

        resolve(newPosition);
      }, 150)
    );
  }
});

export default router;
