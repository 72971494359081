import Vue from "vue";
import VueRouter from "vue-router";

const AboutMe = () => import(/* webpackChunkName: "AboutMePage" */ "@/views/AboutMe");
const AboutMeAbstract = () =>
  import(/* webpackChunkName: "AboutMePage" */ "@/views/AboutMe/AboutMeAbstract");
const WorkExperience = () =>
  import(/* webpackChunkName: "WorkExperiencePage" */ "@/views/AboutMe/WorkExperience");
const Education = () => import(/* webpackChunkName: "EducationPage" */ "@/views/AboutMe/Education");
const Courses = () => import(/* webpackChunkName: "CoursesPage" */ "@/views/AboutMe/Courses");
const Abilities = () => import(/* webpackChunkName: "AbilitiesPage" */ "@/views/AboutMe/Abilities");
const OtherMerits = () =>
  import(/* webpackChunkName: "OtherMeritsPage" */ "@/views/AboutMe/OtherMerits");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AboutMe",
    component: AboutMe,
    redirect: { name: "AboutMeAbstract" },
    children: [
      {
        path: "introduccion",
        name: "AboutMeAbstract",
        component: AboutMeAbstract
      },
      {
        path: "experiencia-laboral",
        name: "WorkExperience",
        component: WorkExperience
      },
      {
        path: "educacion",
        name: "Education",
        component: Education
      },
      {
        path: "cursos",
        name: "Courses",
        component: Courses
      },
      {
        path: "habilidades",
        name: "Abilities",
        component: Abilities
      },
      {
        path: "otros-meritos",
        name: "OtherMerits",
        component: OtherMerits
      }
    ]
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let newPosition = { x: 0, y: 0 };

    return new Promise(resolve =>
      setTimeout(() => {
        if (savedPosition) {
          newPosition = savedPosition;
        } else if (to.hash) {
          newPosition = { selector: to.hash, offset: { x: 0, y: 60 } };
        }

        resolve(newPosition);
      }, 150)
    );
  }
});

export default router;
