<template>
  <app>
    <template v-slot:languageSelection>
      <language-selection />
    </template>
    <template v-slot:content>
      <privacy-policy />
    </template>
  </app>
</template>

<script>
const App = () => import(/* webpackChunkName: "PrivacyPolicyPage" */ "@/App.vue");
const LanguageSelection = () =>
  import(/* webpackChunkName: "PrivacyPolicyPage" */ "@/components/Global/LanguageSelection.vue");
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "PrivacyPolicyPage" */ "@/views/PrivacyPolicy.vue");

export default {
  name: "PrivacyPolicyApp",
  components: {
    App,
    LanguageSelection,
    PrivacyPolicy
  }
};
</script>
