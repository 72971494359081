export default {
  setWindow(state, { attribute, value }) {
    if (attribute) {
      state.window[attribute] = value;
    }
  },
  setOnline(state, value) {
    state.online = value;
  },
  loadAsset(state, data) {
    if (state.assets.toLoad.includes(data) && !state.assets.loaded.includes(data)) {
      state.assets.loaded.push(data);
    }
  },
  setLanguages(state, data) {
    if (data && data.length) {
      state.languages.push(...data);
    }
  },
  setCurrentLanguage(state, data) {
    if (data) {
      state.currentLanguage = data;
    }
  },
  setCurrentPage(state, data) {
    if (data) {
      state.currentPage = data;
    }
  },
  setApiVersion(state, data) {
    if (data) {
      state.apiVersion = data;
    }
  }
};
