export default {
  install(Vue) {
    Vue.prototype.$inputValidation = {
      regex: {
        emailForm: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        textSpace: /^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/,
        generalText: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9,.;?¿¡!\s]*$/
      },
      inputRules: {
        firstname: {
          required: { value: true, text: "contact.messages.required" },
          minlength: { value: 2, text: "contact.messages.minlength" },
          maxlength: { value: 250, text: "contact.messages.maxlength" },
          regex: { value: "textSpace", text: "contact.messages.regexTextSpace" }
        },
        lastname: {
          required: { value: true, text: "contact.messages.required" },
          minlength: { value: 2, text: "contact.messages.minlength" },
          maxlength: { value: 250, text: "contact.messages.maxlength" },
          regex: { value: "textSpace", text: "contact.messages.regexTextSpace" }
        },
        email: {
          required: { value: true, text: "contact.messages.required" },
          minlength: { value: 2, text: "contact.messages.minlength" },
          maxlength: { value: 250, text: "contact.messages.maxlength" },
          regex: { value: "emailForm", text: "contact.messages.regexEmailForm" }
        },
        phone: {
          minlength: { value: 14, placeholder: 10, text: "contact.messages.minlength" },
          maxlength: { value: 14, placeholder: 10, text: "contact.messages.maxlength" }
        },
        company: {
          minlength: { value: 2, text: "contact.messages.minlength" },
          maxlength: { value: 250, text: "contact.messages.maxlength" },
          regex: { value: "generalText", text: "contact.messages.regexGeneralText" }
        },
        description: {
          required: { value: true, text: "contact.messages.required" },
          minlength: { value: 5, text: "contact.messages.minlength" },
          maxlength: { value: 2000, text: "contact.messages.maxlength" },
          regex: { value: "generalText", text: "contact.messages.regexGeneralText" }
        },
        termsAndConditions: {
          required: { value: true, text: "contact.messages.requiredTermsAndConditions" }
        },
        recaptcha: {
          required: { value: true, text: "contact.messages.requiredRecaptcha" }
        }
      },
      /*
       * @function - validateInput
       * Validate input according above rules
       * @param - value (String): Value of the input to validate
       * @param - input (String): Name of the input to validate. Works as reference to obtain the input rules
       */
      validateInput(value = null, input = null) {
        let invalid = null;
        let validationValue = null;

        if (input && this.inputRules[input]) {
          const { required, regex, minlength, maxlength } = this.inputRules[input];

          if (required && required.value && !value) {
            invalid = required.text;
          } else if (
            !invalid &&
            regex &&
            value &&
            regex.value &&
            !this.regex[regex.value].test(value)
          ) {
            invalid = regex.text || true;
          } else if (
            !invalid &&
            minlength &&
            value &&
            minlength.value &&
            value.length < minlength.value
          ) {
            invalid = minlength.text || true;
            validationValue = minlength.placeholder || minlength.value;
          } else if (
            !invalid &&
            maxlength &&
            value &&
            maxlength.value &&
            value.length > maxlength.value
          ) {
            invalid = maxlength.text || true;
            validationValue = maxlength.placeholder || maxlength.value;
          }
        }

        return { invalid, validationValue };
      }
    };
  }
};
