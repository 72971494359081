export default {
  computed: {
    /*
     * @computed - replaceTextClass
     * Gets routes from utils plugin
     */
    replaceTextClass() {
      return (text = "", classToRemove = null, classToAdd = null) => {
        let textToReturn;

        if (
          text &&
          typeof text === "string" &&
          classToRemove &&
          typeof classToRemove === "string" &&
          classToAdd &&
          typeof classToAdd === "string"
        ) {
          textToReturn = text.replaceAll(classToRemove, classToAdd);
        } else {
          textToReturn = text;
        }

        return textToReturn;
      };
    }
  }
};
