import anime from "animejs";
import Neysla from "neysla";
import { openDB } from "idb";
import highlight from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import xml from "highlight.js/lib/languages/xml";
import css from "highlight.js/lib/languages/css";

const { INDEXEDDB_NAME, INDEXEDDB_VERSION, INDEXEDDB_COLLECTION, PUBLIC_API_PATH } = process.env;

export default function({ store }) {
  return {
    install(Vue) {
      function highlightProcess(el, binding) {
        // on first bind, highlight all targets
        el.querySelectorAll("code").forEach(target => {
          // if a value is directly assigned to the directive, use this
          // instead of the element content.
          if (binding.value) {
            target.textContent = binding.value;
          }
          highlight.highlightElement(target);
        });
      }

      highlight.registerLanguage("javascript", javascript);
      highlight.registerLanguage("xml", xml);
      highlight.registerLanguage("css", css);

      Vue.directive("highlight", {
        deep: true,
        bind: highlightProcess,
        componentUpdated: highlightProcess
      });

      Vue.prototype.$dependencies = {
        anime,
        idb: {
          dbPromise: openDB(INDEXEDDB_NAME, INDEXEDDB_VERSION, {
            upgrade(db) {
              db.createObjectStore(INDEXEDDB_COLLECTION);
            }
          }),
          async get(key) {
            return (await this.dbPromise).get(INDEXEDDB_COLLECTION, key);
          },
          async set(key, val) {
            return (await this.dbPromise).put(INDEXEDDB_COLLECTION, val, key);
          },
          async del(key) {
            return (await this.dbPromise).delete(INDEXEDDB_COLLECTION, key);
          },
          async clear() {
            return (await this.dbPromise).clear(INDEXEDDB_COLLECTION);
          },
          async keys() {
            return (await this.dbPromise).getAllKeys(INDEXEDDB_COLLECTION);
          }
        },
        neysla: {
          apiDescription: null,
          translationsContent: null,
          translationsContentPage: null,
          contact: null,
          async initEndpoints() {
            const apiName = "cvApi";
            const { cvApi } = await new Neysla().init({
              url: PUBLIC_API_PATH,
              name: apiName,
              requestType: "json"
            });

            this.apiDescription = cvApi.setModel("/");

            let data;
            if (store.state.Global.online) {
              data = (await this.apiDescription.get()).data;
              await Vue.prototype.$dependencies.idb.set(apiName, data);
            } else {
              data = await Vue.prototype.$dependencies.idb.get(apiName);
            }

            const { version, endpoints } = data;

            if (version) {
              store.commit("Global/setApiVersion", version);
            }

            this.translationsContent = cvApi.setModel(endpoints.translationsContent.model);
            this.translationsContentPage = cvApi.setModel(endpoints.translationsContentPage.model);
            this.contact = cvApi.setModel(endpoints.contact.model);
          }
        },
        recaptcha: {
          /*
           * @function - init
           * This function adds recaptcha script if exists, and append action to be executed when it is loaded
           * @param - action (Function): method to be executed when recaptcha script is loaded
           */
          init(action, locale) {
            const recaptchaId = "recaptchaScript";
            const functionName = "recaptchaLoaded";

            if (document.getElementById(recaptchaId)) {
              action instanceof Function && action();
            } else {
              const recaptchaScript = document.createElement("script");

              window[functionName] = () => {
                if (action instanceof Function) {
                  action();
                } else {
                  console.log("Recaptcha loaded");
                }
              };

              recaptchaScript.setAttribute("id", recaptchaId);
              recaptchaScript.setAttribute("async", "async");
              recaptchaScript.setAttribute("defer", "defer");
              recaptchaScript.setAttribute(
                "src",
                `https://www.google.com/recaptcha/api.js?hl=${locale}&onload=${functionName}&render=explicit`
              );

              document.head.appendChild(recaptchaScript);
            }
          }
        }
      };
    }
  };
}
