export default function({ store }) {
  return {
    install(Vue) {
      Vue.prototype.$utils = {
        defaultLanguage: "es",
        routes: {
          internal: [
            { description: "navigation.aboutMe", icon: ["fas", "user"], href: "/acerca-de-mi/" },
            {
              description: "navigation.myInterests",
              icon: ["fas", "heart"],
              href: "/mis-intereses/"
            },
            {
              description: "navigation.portfolio",
              icon: ["fas", "briefcase"],
              href: "/portafolio/"
            },
            { description: "navigation.contact", icon: ["fas", "envelope"], href: "/contacto/" }
          ],
          external: [
            {
              description: "Linkedin",
              icon: ["fab", "linkedin"],
              href: "https://www.linkedin.com/in/marcos-jesus-chavez-vega-onca"
            },
            { description: "NPM", icon: ["fab", "npm"], href: "https://www.npmjs.com/~onca-vega" },
            { description: "Github", icon: ["fab", "github"], href: "https://github.com/onca-vega" }
          ]
        },
        styles: {
          breakpoints: {
            xsmall: 350,
            small: 576,
            medium: 768,
            large: 992,
            xlarge: 1200,
            xxlarge: 1440,
            xxxlarge: 1920,
            xxxxlarge: 2400,
            xxxxxlarge: 3200
          },
          colorsPalette: {
            mainBlue: "#009bdb",
            mainGreen: "#a3bd31",
            darkPurple: "#261937",
            grayBlue: "#dce7fa",
            liveBlue: "#ade8f1",
            darkGray: "#2c2c2c",
            whiteGray: "#ebebeb",
            strongGray: "#9aa1ae",
            whiteRaw: "#fff",
            darkRaw: "#000"
          }
        },
        /*
         * @function - changeApplicationVisibility
         * This function hides or shows the entire DOM body element
         * @param - state (String): 'show' or 'hide'
         */
        changeApplicationVisibility(state = "show") {
          const { body } = document;

          if (state === "show" && body.classList.contains("hidden")) {
            body.classList.remove("hidden");
          } else if (state === "hide" && !body.classList.contains("hidden")) {
            body.classList.add("hidden");
          }
        },
        /*
         * @function - printCurrentVersion
         * This function logs current application data
         * @param - none
         */
        printCurrentVersion(type = null) {
          if (!type) {
            console.error("printCurrentVersion: type is not defined");
            return;
          }

          if (!store.state.Global.env) {
            console.error("printCurrentVersion: ENV is not defined");
            return;
          }

          const { APPLICATION_NAME, NODE_ENV, VERSION } = store.state.Global.env;

          switch (type) {
            case "ui":
              console.log(`${APPLICATION_NAME} UI (${NODE_ENV}): ${VERSION}`);
              break;
            case "api":
              console.log(
                `${APPLICATION_NAME} API (${NODE_ENV}): ${store.state.Global.apiVersion}`
              );
              break;
            default:
              console.error("printCurrentVersion: What do you want to print?");
          }
        },
        /*
         * @function - getResolutionPair
         * This function returns resolution pair value for 16 : 9 resolution
         * @param - type (String): horizontal or vertical, defines type for value argument
         * @param - value (Number): resolution value for reference
         */
        getResolutionPair(value, type = "horizontal") {
          const resolution = 16 / 9;
          let pairValue = 0;

          if (!isNaN(value)) {
            switch (type) {
              case "horizontal":
                pairValue = value / resolution;
                break;
              case "vertical":
                pairValue = value * resolution;
                break;
              default:
                console.error("getResolutionPair: type not allowed. ", type);
            }
          } else {
            console.error("getResolutionPair: value wrong typed. ", value);
          }

          return pairValue;
        },
        /*
         * @function - scrollTo
         * This function scrolls to element
         * @param - element (DOM element): element in the dom to scroll
         * @param - gap (Number): difference to add at the final scroll position
         */
        scrollTo(element = null, gap = 0) {
          if (!element) {
            return;
          }

          const relativeInputTop = element.getBoundingClientRect().top;
          const relativeBodyTop = document.body.getBoundingClientRect().top;
          const inputTop = Math.abs(Math.round(relativeInputTop - relativeBodyTop)) + gap;

          window.scroll && window.scroll({ top: inputTop, behavior: "smooth" });
        },
        /*
         * @function - getPageRoute
         * Gets page route to navigate around the page
         * @param - none
         */
        getPageRoute(link = "/") {
          const { origin } = window.location;
          const { currentLanguage } = store.state.Global;
          let pageRoute = null;

          if (currentLanguage === this.defaultLanguage) {
            // Spanish
            pageRoute = origin + link;
          } else {
            // English
            pageRoute = `${origin}/${currentLanguage + link}`;
          }

          return pageRoute;
        },
        /*
         * @function - routeIsActive
         * True if route is active, otherwise false
         * @param - none
         */
        routeIsActive(link = "") {
          const { pathname } = window.location;

          return pathname.includes(link);
        }
      };
    }
  };
}
