import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faUser,
  faHeart,
  faBriefcase,
  faClipboard,
  faEnvelope,
  faCode,
  faDotCircle,
  faCaretUp,
  faCaretDown,
  faPlus,
  faSadTear,
  faEllipsisV,
  faEllipsisH,
  faScroll,
  faTimes,
  faCaretLeft,
  faCaretRight,
  faIdCardAlt,
  faUserTie,
  faClipboardList,
  faBookReader,
  faUserGraduate,
  faRunning,
  faPalette,
  faRobot,
  faLightbulb,
  faChartLine,
  faDumbbell,
  faUtensils,
  faHands,
  faGuitar,
  faMusic,
  faCameraRetro,
  faVideo,
  faRedoAlt,
  faCheck,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faNpm, faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "@/registerServiceWorker";
import i18n from "@/i18n";
import store from "@/store";

import Bus from "@/plugins/Bus";
import Dependencies from "@/plugins/Dependencies";
import Utils from "@/plugins/Utils";

import Animations from "@/mixins/Global/Animations";
import DefaultEntryTransition from "@/mixins/Global/DefaultEntryTransition";
import TextProcess from "@/mixins/Global/TextProcess";

library.add(
  faBars,
  faUser,
  faHeart,
  faBriefcase,
  faClipboard,
  faEnvelope,
  faCode,
  faDotCircle,
  faCaretUp,
  faCaretDown,
  faPlus,
  faSadTear,
  faEllipsisV,
  faEllipsisH,
  faScroll,
  faTimes,
  faRedoAlt,
  faCaretLeft,
  faCaretRight,
  faIdCardAlt,
  faUserTie,
  faClipboardList,
  faBookReader,
  faUserGraduate,
  faRunning,
  faPalette,
  faRobot,
  faLightbulb,
  faChartLine,
  faDumbbell,
  faUtensils,
  faHands,
  faGuitar,
  faMusic,
  faCameraRetro,
  faVideo,
  faLinkedin,
  faNpm,
  faGithub,
  faCheck,
  faDownload
);

export default function(component = null, router = null, InputValidation = null) {
  if (!component) {
    console.error("No Component to render defined!");
  }

  const vueConfig = {
    i18n,
    store,
    render: h => h(component)
  };

  if (router) {
    vueConfig.router = router;
  }

  Vue.config.devtools = store.state.Global.env.ENABLE_DEVTOOLS;
  Vue.config.productionTip = false;

  Vue.use(Bus);
  Vue.use(Dependencies({ store }));
  Vue.use(Utils({ store }));

  if (InputValidation) {
    Vue.use(InputValidation);
  }

  Vue.mixin(Animations);
  Vue.mixin(DefaultEntryTransition);
  Vue.mixin(TextProcess);

  Vue.component("font-awesome-icon", FontAwesomeIcon);

  return new Vue(vueConfig).$mount("#app");
}
